import './Index.css'

import Lottie from "lottie-react";
import DataCenterAnimation from '../lotties/data-center.json'

import { Box, Typography } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';

const IndexView = ({randomClassName}: IIndexView) => {
    return (
        <Box className={randomClassName}>
            <Grid
                container
                sx={{ width: '100%', marginX: 0, paddingX: {md: 20}}}
                spacing={10}
                minHeight={"100vh"}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}>
                <Grid
                    xs={12} md={7} lg={8}
                    sx={{ mt: { xs: 10 }, textAlign: 'center', color: 'white' }} >
                    <Typography sx={{ mb: { xs: 7, md: 14 }, typography: { xs: "h2", md: "h1" } }}>
                        Romier Solutions
                    </Typography>
                    <Typography sx={{ typography: { xs: "h4", md: "h3" } }}>
                        AI Driven Polarion Solutions
                    </Typography>
                </Grid>
                <Grid xs={8} md={5} lg={4}>
                    {/* https://lottiefiles.com/67171-data-center */}
                    <Lottie animationData={DataCenterAnimation} loop={true} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default IndexView;