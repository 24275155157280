import { InView } from 'react-intersection-observer';

import { Box } from "@mui/material"

export const SitePart = ({ route, setCurrentRoute }: ISitePart) => {

    const onViewChange = () => {
        setCurrentRoute(route)
    }

    return (
        <InView
            threshold={0.3}
            onChange={inView => { if (inView) onViewChange() }} >
            <Box id={route.id}>
                {route.view}
            </Box>
        </InView>
    )
}