import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React from "react"

export const ImprintModal = ({ open, setOpen }: IImprintModal) => {
    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={"xl"}
                open={open}
                onClose={() => setOpen(false)} >
                <DialogTitle>Impressum</DialogTitle>
                <DialogContent>
                    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                    <p>Lucas Romier<br />
                        Holdersb&uuml;ndt 22<br />
                        77704 Oberkirch</p>

                    <h2>Kontakt</h2>
                    <p>Kontaktformular: <a href="https://romier-solutions.com">https://romier-solutions.com</a> <br />
                        E-Mail: romier.solutions@gmail.com</p>

                    <p>
                        Die Europäische Kommission stellt zur außergerichtlichen Online-Streitbeilegung eine Plattform
                        (sog. OS-Plattform) bereit: <a href="https://www.ec.europa.eu/consumers/odr">www.ec.europa.eu/consumers/odr</a>.
                        Wir sind jedoch weder verpflichtet noch bereit, an dem Streitschlichtungsverfahren teilzunehmen.
                    </p>

                    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}