import { Box, Container, Link, Typography } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';

import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

import LucasImage from '../images/info/me-big.png'
import LucasImageMin from '../images/info/me-low.png'

export const Footer = ({setPrivacyModal, setImprintModal, setCookieModal, randomClassName}: IFooter) => {
    return (
        <Typography
            component="footer"
            className={randomClassName}
            sx={{ display: 'flex', bgcolor: 'primary.light' }} >
            <Container sx={{ my: 4, display: 'flex', position: 'relative', justifyContent: 'center' }}>
                <Grid container spacing={5} xs={12}>
                    <Grid xs={12} md={2}>
                        <Typography variant="h4" sx={{color: 'white', mb: 3}} gutterBottom>
                            Legal
                        </Typography>
                        <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                            <Box component="li" sx={{ py: 0.5, cursor: 'pointer' }} onClick={() => setImprintModal(true)}>
                                <Link sx={{ color: 'white' }} >Imprint</Link>
                            </Box>
                            <Box component="li" sx={{ py: 0.5, cursor: 'pointer' }}>
                                <Link sx={{ color: 'white' }} onClick={() => setPrivacyModal(true)}>Privacy</Link>
                            </Box>
                            <Box component="li" sx={{ py: 0.5, cursor: 'pointer' }}>
                                <Link sx={{ color: 'white' }} href="mailto:lucas.romier@gmail.com" target="_blank">Contact</Link>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid xs={12} md={10}>
                        <Typography variant="h4" sx={{color: 'white', mb: 3}} gutterBottom>
                            Lucas Romier
                        </Typography>
                        <Grid container padding={0} spacing={{xs: 0, lg: 5}} xs={12}>
                            <Grid xs={12} lg={4}>
                                <LazyLoadImage
                                    src={LucasImage}
                                    placeholderSrc={LucasImageMin}
                                    height={200}
                                    effect="blur"
                                    alt="Me, Lucas Romier."
                                />
                            </Grid>
                            <Grid xs={12} lg={8}>
                                <Typography sx={{color: 'white', mb: 2}}>
                                    Hello and welcome! I want to give you the Polarion Experience that your company wants!
                                </Typography>
                                <Typography sx={{color: 'white', mb: 2}}>
                                    For this, I draw on my seven years of experience in the field of Polarion. With more than 20 satisfied customers, I have been working almost exclusively in Deep Learning and Polarion development since 2021.
                                </Typography>
                                <Typography sx={{color: 'white', mb: 2}}>
                                    The most important thing for me is the satisfaction of my customers. Therefore, I strive to respond to each of your wishes in order to realize the optimal solution. I am looking forward to your request!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    )
}