import { ThemeProvider } from "@mui/material";
import { Suspense, useState } from "react";
import { Footer } from "./components/Footer";
import { ImprintModal } from "./components/ImprintModal";

import { Navbar } from "./components/Navbar"
import { PrivacyModal } from "./components/PrivacyModal";
import { SitePart } from "./components/SitePart";

import { DARK_THEME, LIGHT_THEME, ROUTES } from "./Constants";
import IndexView from "./views/IndexView";

const RANDOM_CLASS = `bg-animation-${Math.floor(Math.random() * 7)}`;
const DEFAULT_ROUTE: IRoute = {
  id: 'index',
  name: 'Romier Solutions',
  transparentNavbar: true,
  view: <IndexView randomClassName={RANDOM_CLASS} />
};

export const App = () => {
  const [currentRoute, setCurrentRoute] = useState<IRoute>(DEFAULT_ROUTE)

  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false)
  const [imprintModalOpen, setImprintModalOpen] = useState<boolean>(false)
  // eslint-disable-next-line
  const [cookieModalOpen, setCookieModalOpen] = useState<boolean>(false)

  const [randomClassName] = useState(RANDOM_CLASS);

  return (
    <div className="App">
      <ThemeProvider theme={DARK_THEME}>
        <Navbar routes={ROUTES} currentRoute={currentRoute} />
      </ThemeProvider>

      <ThemeProvider theme={LIGHT_THEME}>
        <SitePart route={DEFAULT_ROUTE} setCurrentRoute={setCurrentRoute} />

        <Suspense>
          {ROUTES.map(route => {
            return <SitePart key={route.id} route={route} setCurrentRoute={setCurrentRoute} />
          })}
        </Suspense>

        <Footer setPrivacyModal={setPrivacyModalOpen} setImprintModal={setImprintModalOpen} setCookieModal={setCookieModalOpen} randomClassName={randomClassName} />

        <PrivacyModal open={privacyModalOpen} setOpen={setPrivacyModalOpen} setCookieModal={setCookieModalOpen} />
        <ImprintModal open={imprintModalOpen} setOpen={setImprintModalOpen} />

      </ThemeProvider>
    </div>
  )
}