import React, { useState } from 'react'

import './Navbar.css'

import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import logo from '../images/logos/romier_solutions_transparent-05-navbar.png'

export const Navbar = ({ routes, currentRoute }: INavbar) => {
    const [anchorNavbar, setAnchorNavbar] = useState<HTMLButtonElement | null>(null);

    const scrollTo = (part: string) => {
        const section = document.querySelector(`#${part}`);
        
        if (!section) return;
        section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    return (
        <AppBar position='sticky' className={currentRoute.transparentNavbar ? 'transparent-navbar' : ''}>
            <Container maxWidth="xl" sx={{ maxHeight: '100px' }}>
                <Toolbar disableGutters>
                    {/* Display on small screens */}
                    <Box sx={{ display: { xs: 'flex', md: 'none', flex: 1 } }}>
                        <IconButton
                            size="large"
                            aria-label="open menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={e => setAnchorNavbar(e.currentTarget)}
                            color="inherit" >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorNavbar}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorNavbar)}
                            onClose={_ => setAnchorNavbar(null)}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }} >
                            {routes.map((route) => (
                                <MenuItem onClick={() => scrollTo(route.id)} key={route.id}>
                                    <Typography textAlign="center">{route.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                        <Box component='img' sx={{ height: '55px' }} src={logo} alt='Romier Solutions Logo' />
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 2, flex: 1 }} />

                    {/* Display on all other screen */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <Box component='img' onClick={() => scrollTo('index')} sx={{ height: '55px', cursor: 'pointer' }} src={logo} alt='Romier Solutions Logo' />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {routes.map((route) => (
                            <Button
                                className={route.id === currentRoute.id ? 'active' : ''}
                                key={route.id}
                                onClick={() => scrollTo(route.id)}
                                sx={{
                                    my: 2,
                                    color: 'white',
                                    display: 'block',
                                    ':hover': {
                                        bgcolor: 'transparent',
                                        textDecoration: 'underline'
                                    }
                                }} >
                                {route.name}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}