import { lazy } from "react"

import { createTheme } from "@mui/material"

const ExamplesView = lazy(() => import("./views/ExamplesView"));
const MyOffersView = lazy(() => import("./views/MyOffersView"));

const ROUTES: IRoute[] = [
    {
        id: "my-offers",
        name: "My Offers",
        transparentNavbar: false,
        view: <MyOffersView />,
    },
    {
        id: "examples",
        name: "Examples",
        transparentNavbar: false,
        view: <ExamplesView />,
    }
]

const DARK_THEME = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FF7C66',
        },
        secondary: {
            main: '#F5AF19'
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
})

const LIGHT_THEME = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#FF7C66',
        },
        secondary: {
            main: '#F5AF19'
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
})

export {
    ROUTES,
    DARK_THEME,
    LIGHT_THEME,
}